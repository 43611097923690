
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { Loader } from "@googlemaps/js-api-loader";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
import AppService, {
  refreshSelectMedia,
  renderImageUrl,
  renderImageUrlEditor,
  showMultipleMedia,
  showSingleMedia,
} from "@/core/services/AppService";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Experience, ExperienceDefault } from "@/store/model/Experience";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { content, contentDefault } from "@/store/model";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Editor from "@tinymce/tinymce-vue";

export default defineComponent({
  name: "add_experience",
  components: { editor: Editor },
  setup() {
    let objectData = ref<Experience>(
      JSON.parse(JSON.stringify(ExperienceDefault)) as Experience
    );
    const mapRef = ref();
    const image_editor = ref();
    const image_news = ref();
    const isMultipleMedia = computed(() => store.getters["getIsMultiple"]);
    const map_api_key = process.env.VUE_APP_MAP_API_KEY;
    const centerMap = ref({ lat: 21.0362368, lng: 105.7905825 });
    const markerOptions = ref({ position: centerMap, label: "L", title: "" });
    let mapDiv = ref<HTMLDivElement>();
    let map: any;
    const loader = new Loader({
      apiKey: process.env.VUE_APP_MAP_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });
    const media_actions = ref(["images", "thumbnail"]);
    let x =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.getElementsByTagName("body")[0].clientWidth;
    let y =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName("body")[0].clientHeight;
    const account_current = ref();
    const editorConfig = {
      image_advtab: true,
      image_caption: true,
      image_description: true,
      image_uploadtab: true,
      height: 700,
      menubar: true,
      file_picker_callback: function (callback, value, meta) {
        let url = "/manager-media?type=editor";
        const instanceApi = getTinymce().activeEditor.windowManager.openUrl({
          url: url,
          title: "Quản lý media",
          width: x * 0.8,
          height: y * 0.95,
          onMessage: (api, message) => {
            console.log("message", message, api, meta.message, value);
          },
          onClose: async (instance, trigger) => {
            let dataImage = JSON.parse(image_editor.value);
            //console.log(dataImage);
            let image = renderImageUrlEditor(dataImage);
            let width = dataImage.width ? dataImage.width : "800";
            if (width > 1200) width = 1000;
            callback(image, {
              text: dataImage["path"],
              alt: dataImage["originalname"],
              width: width.toString(),
              height: "auto",
            });
          },
          onAction: function (instance, trigger) {
            // code for when "custom" buttons are triggered
            //console.log("instance", instance, trigger);
          },
        });
      },
      plugins: [
        "advlist autolink lists link charmap",
        "searchreplace visualblocks code fullscreen",
        "print preview anchor insertdatetime media image",
        "paste code  wordcount table advtable",
      ],
      toolbar:
        "insertfile  |formatselect | bold italic strikethrough underline |backcolor  color | subscript superscript |   alignleft aligncenter alignright alignjustify |  outdent  indent  anchor undo redo | lists   bullist numlist outdent indent | table |image|code|fullscreen",
    };
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const listDataProvince = ref();
    const listDataDistrict = ref();
    const listDataWard = ref();
    const listDataStreet = ref();

    const currPos = ref({
      lat: 21,
      lng: 105,
    });
    const otherPos = ref(null);
    let clickListener = null;
    const originAutocomplete = ref();
    const Markers = ref([]);
    onMounted(async () => {
      await loader.load();
      originAutocomplete.value = new window.google.maps.places.Autocomplete(
        document.getElementById("address") as HTMLInputElement,
        {
          bounds: new window.google.maps.LatLngBounds({
            lat: 21.0362368,
            lng: 105.7905825,
          }),
        }
      );

      originAutocomplete.value.addListener("place_changed", () => {
        console.log(originAutocomplete.value.getPlace());
        let place = originAutocomplete.value.getPlace().geometry.location;
        currPos.value = { lat: place.lat(), lng: place.lng() };
        objectData.value.location.latitude = place.lat();
        objectData.value.location.longitude = place.lng();
        new window.google.maps.Marker({
          position: currPos.value,
          label: "T",
          map: map,
        });
        map.setCenter(currPos.value);
      });
      if (mapDiv.value) {
        map = new window.google.maps.Map(mapDiv.value, {
          center: currPos.value,
          zoom: 14,
        });
      }
      map.addListener("click", ({ latLng: { lat, lng } }) => {
        currPos.value = { lat: lat(), lng: lng() };
        new window.google.maps.Marker({
          position: currPos.value,
          label: "T",
          map: map,
        });
        objectData.value.location.latitude = lat();
        objectData.value.location.longitude = lng();
      });
      setCurrentPageBreadcrumbs("Tạo review mới", ["Review"]);
      MenuComponent.reinitialization();
      account_current.value = store.getters["currentUser"];
      await AppService.getDataProvince("")
        .then(async (res: any) => {
          listDataProvince.value = res;
        })
        .catch((error) => {
          console.error(error);
        });
      objectData.value.published_at = new Date();
      objectData.value.publish_by = account_current.value._id;
    });

    const onChangeLocation = (value) => {
      AppService.getDataProvince(value)
        .then(async (res: any) => {
          listDataDistrict.value = res;
          objectData.value.location.district_id = null;
          objectData.value.location.ward_id = null;
          objectData.value.location.street_id = null;
        })
        .catch((error) => {
          console.error(error);
        });

      let province = listDataProvince.value.filter(
        (item) => item._id === objectData.value.location.province_id
      );
      objectData.value.location.province_name = province[0].name;
    };

    const onChangeDistrict = (value) => {
      AppService.getDataProvince(value)
        .then(async (res: any) => {
          listDataWard.value = res;
          objectData.value.location.ward_id = null;
          objectData.value.location.street_id = null;
        })
        .catch((error) => {
          console.error(error);
        });
      AppService.getDataStreet(value)
        .then(async (res: any) => {
          listDataStreet.value = res;
          objectData.value.location.street_id = null;
        })
        .catch((error) => {
          console.error(error);
        });
      let district = listDataDistrict.value.filter(
        (item) => item._id === objectData.value.location.district_id
      );
      objectData.value.location.district_name = district[0].name;
    };

    const onChangeWard = (value) => {
      let ward = listDataWard.value.filter(
        (item) => item._id === objectData.value.location.ward_id
      );
      objectData.value.location.ward_name = ward[0].name;
    };

    const onChangeStreet = (value) => {
      let street = listDataStreet.value.filter(
        (item) => item._id === objectData.value.location.street_id
      );
      objectData.value.location.street_name = street[0].name;
    };

    const addContent = () => {
      objectData.value.contents.push(
        JSON.parse(JSON.stringify(contentDefault)) as content
      );
    };

    const removeContent = (index) => {
      if (index > -1) {
        objectData.value.contents.splice(index, 1);
      }
    };

    const message = "Vui lòng không để trống";

    const required_select = {
      required: true,
      message: message,
      trigger: "change",
    };

    const rules = ref({
      name: [
        {
          required: true,
          message: "Tên dự án không được để trống",
          trigger: "blur",
        },
      ],
      slug: [
        {
          required: true,
          message: "Đường đẫn dự án không được để trống",
          trigger: "blur",
        },
      ],
      type: [
        {
          required: true,
          message: "Loại dự án không được để trống",
          trigger: "blur",
        },
      ],
      content: [
        {
          required: true,
          message: "Nội dung không được để trống",
          trigger: "blur",
        },
      ],
      categories: [
        {
          required: true,
          message: "Danh mục dự án không được để trống",
          trigger: ["blur", "change"],
        },
      ],
      published_at: [
        {
          required: true,
          message: "Ngày đăng tin không được để trống",
          trigger: "blur",
        },
      ],
      "location.province_id": [required_select],
      "location.district_id": [required_select],
      "location.ward_id": [required_select],
    });
    const convertNameToSlug = (value) => {
      objectData.value["slug"] = AppService.convertSlug(value);
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (objectData.value.location.street_id == "") {
            objectData.value.address =
              objectData.value.location.ward_name +
              ", " +
              objectData.value.location.district_name +
              ", " +
              objectData.value.location.province_name;
          } else {
            objectData.value.address =
              objectData.value.location.street_name +
              ", " +
              objectData.value.location.ward_name +
              ", " +
              objectData.value.location.district_name +
              ", " +
              objectData.value.location.province_name;
          }
          // loading.value = true;

          // objectData.value.location.latitude = ?currPos.value.lat:parseFloat(currPos.value.lat)
          // objectData.value.location.longitude = typeof (currPos.value.lng) === "number"?currPos.value.lng:parseFloat(currPos.value.lng)
          store
            .dispatch("ADD_EXPERIENCE", objectData.value)
            .then(() => {
              Swal.fire({
                text: "Cập nhật thông dự án thành công",
                icon: "success",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Danh sách",
                cancelButtonText: "Tiếp tục chỉnh sửa",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              }).then(async function (result) {
                if (result.isConfirmed) {
                  await router.push({ name: "experiences" });
                } else {
                  let data = await store.getters["currentExperience"];
                  await router.push({
                    name: "experiences-edit",
                    params: { id: data._id },
                  });
                }
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          return false;
        }
      });
    };

    watch(
      () => store.state.MediaModule.actionCallback,
      (action, second) => {
        if (media_actions.value.includes(action)) {
          let fields = action.split(".");
          if (fields.length > 1) {
            if (isMultipleMedia.value) {
              //console.log(action,fields);
              objectData.value[fields[0]][fields[1]] = objectData.value[
                fields[0]
              ][fields[1]].concat(store.getters["getSelectMultiMedia"]);
            } else {
              objectData.value[fields[0]][fields[1]] =
                store.getters["getSelectMedia"];
            }
          } else {
            if (isMultipleMedia.value) {
              objectData.value[action] = store.getters[
                "getSelectMultiMedia"
              ].concat(objectData.value[action]);
            } else {
              objectData.value[action] = store.getters["getSelectMedia"];
            }
          }
          refreshSelectMedia();
        }
      }
    );
    const removeImages = (action) => {
      let fields = action.name.split(".");
      if (fields.length > 1) {
        objectData.value[fields[0]][fields[1]].splice(action.index, 1);
      } else {
        objectData.value[fields[0]].splice(action.index, 1);
      }
    };

    const checkDefaultImage = (image) => {
      if (image.name === "default-placeholder.jpg") {
        return false;
      } else {
        return true;
      }
    };

    return {
      rules,
      submit,
      editorConfig,
      removeImages,
      checkDefaultImage,
      convertNameToSlug,
      renderImageUrl,
      showSingleMedia,
      showMultipleMedia,
      loading,
      objectData,
      formRef,
      image_editor,
      image_news,
      listDataProvince,
      listDataDistrict,
      listDataWard,
      listDataStreet,
      onChangeLocation,
      onChangeDistrict,
      onChangeWard,
      onChangeStreet,
      addContent,
      removeContent,
      centerMap,
      map_api_key,
      mapRef,
      markerOptions,
      currPos,
      otherPos,
      mapDiv,
    };
  },
  mounted() {
    //Event Listener for Iframe
    window.addEventListener("message", this.iframeEvent, false);
  },
  methods: {
    iframeEvent(event) {
      //Verify App Domain
      if (event.data.action == "editor") {
        this.image_editor = event.data.image;
        //console.log("data received editor:  ", event.data);
      }
      if (event.data.action == "image-feature") {
        this.objectData["image"] = JSON.parse(event.data.image);
        this.image_news = JSON.parse(event.data.image);
        //console.log("image-feature:  ", event.data.image);
      }
    },
  },
});
